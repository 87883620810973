import constants from "../constants";

export const setSingleData = (single, data) => ({
  type: constants.SINGLE_DATA,
  value: {single, data},
});

export const updateCurrentState = (single, state) => ({
  type: constants.SINGLE_STATE, 
  value: {single, state},
});

export const updateLoadingState = (single, loadingState) => ({
  type: constants.SINGLE_LOADING_STATE, 
  value: {single, loadingState},
});